import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Sparkles
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTIgMy0xLjkxMiA1LjgxM2EyIDIgMCAwIDEtMS4yNzUgMS4yNzVMMyAxMmw1LjgxMyAxLjkxMmEyIDIgMCAwIDEgMS4yNzUgMS4yNzVMMTIgMjFsMS45MTItNS44MTNhMiAyIDAgMCAxIDEuMjc1LTEuMjc1TDIxIDEybC01LjgxMy0xLjkxMmEyIDIgMCAwIDEtMS4yNzUtMS4yNzVMMTIgM1oiIC8+CiAgPHBhdGggZD0iTTUgM3Y0IiAvPgogIDxwYXRoIGQ9Ik0xOSAxN3Y0IiAvPgogIDxwYXRoIGQ9Ik0zIDVoNCIgLz4KICA8cGF0aCBkPSJNMTcgMTloNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/sparkles
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const Sparkles = createLucideIcon('SparklesIcon', [
  [
    'path',
    {
      d: 'm12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z',
      key: '17u4zn',
    },
  ],
  ['path', { d: 'M5 3v4', key: 'bklmnn' }],
  ['path', { d: 'M19 17v4', key: 'iiml17' }],
  ['path', { d: 'M3 5h4', key: 'nem4j1' }],
  ['path', { d: 'M17 19h4', key: 'lbex7p' }],
]);

export default Sparkles;
