import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Bold
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTQgMTJhNCA0IDAgMCAwIDAtOEg2djgiIC8+CiAgPHBhdGggZD0iTTE1IDIwYTQgNCAwIDAgMCAwLThINnY4WiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/bold
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const Bold = createLucideIcon('BoldIcon', [
  ['path', { d: 'M14 12a4 4 0 0 0 0-8H6v8', key: 'v2sylx' }],
  ['path', { d: 'M15 20a4 4 0 0 0 0-8H6v8Z', key: '1ef5ya' }],
]);

export default Bold;
